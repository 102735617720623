<template>
  <div class="mainform">
    <div class="mainHeader">
      <span v-if="type == 1">新建外协入库单</span>
      <span v-if="type == 2">新建供应链入库单</span>
      <span v-if="type == 3">新建普通物料入库单</span>
      <span v-if="id">入库单详情</span>
      <span @click="back" class="back">返回></span>
    </div>
    <div class="form mg-form perch-mg-form">
      <el-form class="content bascform" ref="form" :class="{disabled:formDisabled}" :disabled="formDisabled" :model="form" label-width="120px">
        <div v-if="id">
          <div class="col col4">
            <el-form-item label="入库单编号">
              <el-input v-model="dataBox.ruleSupplier" placeholder="请输入"></el-input>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="入库时间">
              <el-input v-model="dataBox.createDate" placeholder="请输入"></el-input>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="操作人">
              <el-input v-model="dataBox.createUserId" placeholder="请输入"></el-input>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="操作类型">
              <el-input v-model="dataBox.ruleType" placeholder="请输入"></el-input>
            </el-form-item>
          </div>
          <div class="col col4" v-if="id">
            <el-form-item label="状态">
              <el-input v-model="dataBox.proofType" placeholder="请输入"></el-input>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="入库单类型">
              <!-- <el-input v-model="dataBox.ruleOrdersType" placeholder="请输入"></el-input> -->
              <span style="color: #666; padding-left: 15px">
              {{dataBox.ruleOrdersType=="1"?"外协订单入库":dataBox.ruleOrdersType=="2"?"供应链订单入库":dataBox.ruleOrdersType=="3"?"普通物料入库":""}}
            </span>
            </el-form-item>
          </div>
        </div>
        <div class="purchClass">
          <el-form-item label="入库物料">
            <div class="clearfix" v-if="!id">
              <div class="btnlaft mg-btnlaft">
                <el-button v-if="type == 3" size="small" @click="showMateriel" type="primary" plain>添加物料</el-button>
                <el-button size="small" @click="showOrder" type="primary" plain>按订单入库</el-button>
                <!-- <el-button v-if="type == 3" size="small" @click="showDelivery" type="primary" plain>按交货单入库</el-button> -->
              </div>
              <div class="btnlaft mg-btnlaft">
                <el-upload
                  ref="upload"
                  name="file"
                  :auto-upload="true"
                  :action= 'postUrl'
                  :show-file-list="false"
                  :on-exceed="handleExceed"
                  :on-change="handleChansge"
                  :on-success="handSuccess"
                  :on-error="handError"
                  :headers="tokenHeader"
                >
                <el-button size="small" type="primary" plain>上传excel</el-button>
              </el-upload>
              </div>
              <div class="downMb">
                <el-button v-if="type == 3" @click="downCommonTemplate" size="small" type="primary" plain>下载模板</el-button>
                <el-button v-if="type != 3" @click="downOtherTemplate" size="small" type="primary" plain>下载模板</el-button>
              </div>
            </div>
            <div class="excel-tab">
              <el-table size=mini :data="form.bidScoringList" stripe style="width: 100%">
                <el-table-column prop="indexNum" type="index" label="序号" width="50"></el-table-column>
                <el-table-column prop="materialCode" label="物料编号" :show-overflow-tooltip="true" width="130">
                  <template #default="scope" v-if="type == 3 && btnStarts == 1">
                    <el-input v-model="scope.row.materialCode" @blur="itCodeBlur(scope.row)" @keyup.enter="itCodeEnter(scope.row, $event)" placeholder="请输入"></el-input>
                  </template>
                </el-table-column>
                <el-table-column prop="materialDesc" label="物料描述" :show-overflow-tooltip="true" width=""></el-table-column>
                <!-- <el-table-column prop="materialType" label="物料类别" :show-overflow-tooltip="true" width="100">
                  <template #default="scope">
                    {{scope.row.materialType=="1"?"普通":scope.row.materialType=="2"?"专用":scope.row.materialType=="3"?"AVAP":""}}
                  </template>
                </el-table-column> -->

                <!-- 详情展示 -->
                <el-table-column v-if="ruleOrdersType == 2 && id" prop="ruleOrders" label="采购单编号" width="130" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column v-if="ruleOrdersType == 2 && id" prop="purchaseNum" label="采购单行号" width="130" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column v-if="ruleOrdersType == 2 && id" prop="number" label="采购单行数量" width="130" :show-overflow-tooltip="true"></el-table-column>

                <el-table-column v-if="deliveryType == 2 && id" prop="delivery" label="送货单编号" width="130" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column v-if="deliveryType == 2 && id" prop="deliveryNum" label="送货单行号" width="130" :show-overflow-tooltip="true"></el-table-column>

                <!-- 创建入库单 -->
                <el-table-column v-if="type == 1 || type == 2 || ruleOrdersShow == 2" prop="orderCode" label="采购单编号" width="130" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column v-if="type == 1 || type == 2 || ruleOrdersShow == 2" prop="orderRow" label="采购单行号" width="130" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column v-if="type == 1 || type == 2 || ruleOrdersShow == 2" prop="number" label="采购单行数量" width="130" :show-overflow-tooltip="true"></el-table-column>

                <el-table-column v-if="type == 3 && cgdStart == 2" prop="livraisonCode" label="送货单编号" width="130" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column v-if="type == 3 && cgdStart == 2" prop="livraisonRow" label="送货单行号" width="130" :show-overflow-tooltip="true"></el-table-column>

                <el-table-column prop="units" label="单位" :show-overflow-tooltip="true" width="60"></el-table-column>
                <el-table-column prop="materialNum" label="入库数量" :show-overflow-tooltip="true" width="100">
                  <template #default="scope" v-if="!id">
                    <el-input v-model="scope.row.materialNum" @input="(val)=>{scope.row.materialNum = val.replace(/\D|^[0]+/g,'')}" placeholder="请输入"></el-input>
                  </template>
                </el-table-column>

                <el-table-column prop="storage" label="库位" width="140" v-if="id" :show-overflow-tooltip="true"></el-table-column>

                <el-table-column prop="ruleStorage" label="库位" :show-overflow-tooltip="true" v-if="!id" width="120">
                  <template  #header>
                    <el-select
                    v-model="form.ruleStorage"
                    filterable
                    remote
                    reserve-keyword
                    value-key="id"
                    placeholder="库位"
                    @change="handleSelect"
                    @focus="clearDataTop"
                    :remote-method="querySearch">
                      <el-option
                        v-for='item in newSelect'
                        :key='item.id'
                        :label='item.lable'
                        :value='item'>
                      </el-option>
                    </el-select>
                  </template>

                  <template #default="scope">
                    <el-select
                    v-model="scope.row.ruleStorage"
                    filterable
                    remote
                    reserve-keyword
                    value-key="id"
                    placeholder="库位"
                    @focus="clearData(scope.row)"
                    :remote-method="querySearch">
                      <el-option
                        v-for='item in newSelect'
                        :key='item.id'
                        :label='item.lable'
                        :value='item'>
                      </el-option>
                    </el-select>
                  </template>
                </el-table-column>

                <el-table-column v-if="!id" label="操作" width="90">
                  <template #default="scope">
                    <div v-if="type == 3 && btnStarts == 1">
                      <template v-if="form.bidScoringList.length === 1">
                        <el-button size="mini" type="primary" @click="addBidTeam(scope.$index)" icon="el-icon-plus" circle></el-button>
                      </template>
                      <template v-else-if="form.bidScoringList.length === scope.$index + 1">
                        <el-button size="mini" type="primary" @click="removeBidTeam(scope.$index)" icon="el-icon-minus" circle></el-button>
                        <el-button size="mini" type="primary" @click="addBidTeam(scope.$index)" icon="el-icon-plus" circle></el-button>
                      </template>
                      <template v-else>
                        <el-button size="mini" type="primary" @click="removeBidTeam(scope.$index)" icon="el-icon-minus" circle></el-button>
                      </template>
                    </div>
                    <div v-else>
                      <el-button size="mini" type="primary" @click="removeBidTeamType(scope.$index)" icon="el-icon-minus" circle></el-button>
                    </div>
                  </template>
                </el-table-column>
                <!-- <el-table-column v-if="id" prop="storageType" label="库位类型" :show-overflow-tooltip="true" width="80"></el-table-column> -->
              </el-table>
            </div>
          </el-form-item>
        </div>
        <el-form-item label="备注">
          <el-input type="textarea" v-model="dataBox.ruleComment" placeholder="请填写备注"></el-input>
        </el-form-item>
        <div class="action mg-action" v-if="!id">
          <el-button class="submit" type="primary" size="medium" @click="preservation">提交入库</el-button>
        </div>
      </el-form>
      <div v-if="dataBox.proofType === '已入库'">
        <div class="action" v-if="id">
          <el-button class="submit" type="primary" size="medium" @click="deliverGoods">撤销</el-button>
        </div>
      </div>
    </div>
    <dialog-box ref="queryDialog" dialogWidth="80%" :dialogShow="dialogShow" @handleClose='handleClose' title="添加物料" componentName="AddMaterielInLibraryBody" :buttonData="[{ label: '取消', action: 'handleClose', size: 'mini' }, {label:'确认',action:'action', type:'primary', size:'mini'}]" @action="action"></dialog-box>

    <dialog-box ref="queryDialog" dialogWidth="80%" :dialogShow="dialogOrderShow" :dataBox="dataBox" :type="type" @handleClose='handleOrderClose' title="选择订单" componentName="AddMaterielInOrderBody" :buttonData="[{ label: '取消', action: 'handleClose', size: 'mini' },{label:'确认',action:'orderAction', type:'primary', size:'mini'}]" @orderAction="orderBtn" ></dialog-box>

    <dialog-box ref="queryDialog" dialogWidth="80%" :dialogShow="dialogDeliveryShow" :dataBox="dataBox" @handleClose='handleDeliveryClose' title="选择交货单" componentName="AddMaterielInDeliveryBody" :buttonData="[{ label: '取消', action: 'handleClose', size: 'mini' },{label:'确认',action:'deliveryAction', type:'primary', size:'mini'}]" @deliveryAction="deliveryBtn" ></dialog-box>
  </div>
</template>
<script>
import Component from '@/assets/js/components.js'
import { baseURL, request, requestForm, exportForm } from '@/assets/js/http.js'
export default {
  components: Component.components,
  name: 'AddMaterielInLibrary',
  data: function () {
    return {
      formDisabled: false,
      id: this.$route.query.id,
      type: this.$route.query.type,
      dialogShow: false,
      dialogOrderShow: false,
      dialogDeliveryShow: false,
      newSelect: [],
      dataBox: {},
      btnStarts: 1,
      ruleOrdersShow: 1,
      cgdStart: 1,
      clrarList: 1,
      menudata: this.$store.state.ViewMenuData.buttonData,
      ruleOrdersType: 1,
      deliveryType: 1,
      tokenHeader: {
        token: localStorage.getItem('token')
      },
      postUrl: baseURL + '/api/order/PurchasingSystem/upload/materialOrOrder',
      form: {
        // businessScope: '',
        // natureBusiness: '',
        // formData: {},
        xjDescribe: '',
        creationDate: '',
        createUsername: '',
        proofType: '',
        ruleType: '',
        bidScoringList: [{}]
      }
    }
  },
  mounted () {
    if (this.id) {
      this.formDisabled = true
    }
    // 绑定enter事件
    // window.addEventListener('keyup', this.itCodeEnter)
  },
  onUnmounted () {
    // 销毁enter事件
    // window.removeEventListener('keyup', this.itCodeEnter)
  },
  created () {
    if (this.type === '1' || this.type === '2') {
      this.form.bidScoringList = []
    }
    this.getIdData()
  },
  watch: {
    '$store.state.ViewMenuData': function (val) {
      this.menudata = val.menuData
    }
  },
  methods: {
    // 根据ID请求数据
    getIdData () {
      const id = this.id
      if (id) {
        request('/api/order/supplier/queryEkpo?' + 'id=' + id, 'get').then((res) => {
          if (res.code === '200') {
            this.dataBox = res.data.ruleMaterialProofDto
            this.form.bidScoringList = res.data.ruleMaterialProofDtos
            this.form.bidScoringList.forEach(item => {
              // if (item.ruleOrders !== null && item.purchaseNum !== null) {
              //   this.ruleOrdersType = 2
              // }
              // if (item.delivery !== null && item.deliveryNum !== null) {
              //   this.deliveryType = 2
              // }
              if (item.ruleOrders && item.purchaseNum) {
                this.ruleOrdersType = 2
              }
              if (item.delivery && item.deliveryNum) {
                this.deliveryType = 2
              }
            })
          }
        })
      }
    },
    // 表头库存输入下拉框选中值以后，对应整列下拉框赋值
    handleSelect (val) {
      this.form.bidScoringList.forEach(item => {
        item.ruleStorage = val
      })
    },
    // 表格里的选择库位输入后匹配内容
    querySearch (queryString) {
      var str = queryString.toString()
      // 匹配库位
      request('/api/livraison/entrepot/getEntrepotDataList?' + 'str=' + str, 'get').then((res) => {
        var newListEnt = []
        res.data.forEach(item => {
          newListEnt.push({
            lable: item.entrPosiName,
            id: item.id,
            storageType: item.entrPosiType,
            storageCode: item.entrPosiCode,
            receivingAddress: item.entrPosiAddr
          })
        })
        this.newSelect = newListEnt
      })
    },
    // 判断库位输入库是否有值
    clearData (row) {
      if (row.ruleStorage) {
        this.newSelect = []
      }
    },
    // 点击表头库位搜索时清空数据
    clearDataTop () {
      this.newSelect = []
    },
    // 添加一条数据
    addBidTeam (index) {
      this.form.bidScoringList.push({
        bidScoringList: []
      })
    },
    // 普通物料入库删除一条数据
    removeBidTeam (index) {
      this.form.bidScoringList.splice(index, 1)
    },
    // 外协订单/供应链订单入库删除一条数据
    removeBidTeamType (index) {
      this.form.bidScoringList.splice(index, 1)
    },
    // 判断是否只能上传一个文件，超过提示报错
    handleExceed (e) {
      this.$message({
        showClose: true,
        type: 'error',
        message: '只能上传一个文件'
      })
    },
    // 验证上传文件格式
    handleChansge (file, fileList) {
      if (!/\.(xlsx|xls|XLSX|XLS)$/.test(file.name)) {
        this.$message({
          showClose: true,
          type: 'error',
          message: '上传文件只能为excel文件，且为xlsx,xls格式'
        })
        return false
      }
    },
    // 上传失败提示
    handError () {
      this.$message({
        showClose: true,
        type: 'error',
        message: '文件上传失败'
      })
    },
    // 上传成功或错误提示
    handSuccess (response, file, fileList) {
      if (response.code === '200') {
        this.btnStarts = 2
        this.clrarList = 2 // 清空列表
        // this.form.bidScoringList = response.data
        this.form.bidScoringList = []
        response.data.forEach(item => {
          this.form.bidScoringList.push({
            materialCode: item.materialCode,
            materialDesc: item.materialDesc,
            units: item.unit,
            materialNum: item.storageNum,
            ruleStorage: item.storageLocation,
            orderCode: item.order,
            orderRow: item.order
          })
        })
      } else {
        this.$message({
          showClose: true,
          type: 'error',
          message: response.msg
        })
      }
    },
    // 根据物料编号请求订单信息
    queryRulNum (row) {
      const obj = {
        materialCode: row.materialCode
      }
      requestForm('/api/order/supplier/getOnlyBySupplierErpCodeAndMaterialCodeS', 'POST', obj).then((res) => {
        if (res.code === '200') {
          if (res.data.code === '200') {
            this.form.bidScoringList = [res.data.data]
            this.form.bidScoringList.forEach(item => {
              item.units = item.unit
            })
          } else {
            this.$message({
              showClose: true,
              type: 'error',
              message: res.data.msg
            })
          }
        }
      })
    },
    // 失去焦点查询物料编号订单信息
    itCodeBlur (row) {
      this.queryRulNum(row)
    },
    // 物料编号录入后回车查询物料编号订单信息
    itCodeEnter (row, event) {
      this.queryRulNum(row)
      if (event) {
        event.target.blur()
      }
    },

    // 提交入库
    preservation () {
      var isHide = true
      this.form.bidScoringList.forEach(r => {
        if (!r.materialNum) {
          isHide = false
        }
      })
      this.form.bidScoringList.forEach(r => {
        if (!r.ruleStorage) {
          isHide = false
        }
      })
      if (isHide) {
      } else {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '请完善入库物料信息'
        })
        return
      }

      if (!this.dataBox.ruleComment) {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '请填写备注信息'
        })
        return
      }

      this.form.bidScoringList.forEach(item => {
        item.storage = item.ruleStorage.lable
        item.storageType = item.ruleStorage.storageType
        item.storageCode = item.ruleStorage.storageCode
        item.receivingAddress = item.ruleStorage.entrPosiAddr
        if (item.materiaUnit !== undefined) {
          item.units = item.materiaUnit
        } else {
          item.units = item.unit
        }
      })
      const obj = {
        inventoryMaterialDat: {
          ruleComment: this.dataBox.ruleComment,
          ruleOrdersType: this.type,
          ruleSupplierName: this.dataBox.ruleSupplierNumber,
          scopeOfBusiness: this.dataBox.businessScope
        },
        ruleMaterialInDtos: [
          ...this.form.bidScoringList
        ]
      }
      request('/api/order/supplier/insertPurchasinglier', 'post', obj).then((res) => {
        if (res.code === '200') {
          this.$message({
            showClose: true,
            type: 'success',
            message: '操作成功!'
          })
          this.$router.push({
            path: '/InventoryDelivery/MaterielInLibrary'
          })
        }
      })
    },
    // 返回
    back: () => {
      window.history.back()
    },
    // 添加物料
    showMateriel () {
      this.dialogShow = true
    },
    // 按订单入库
    showOrder () {
      this.dialogOrderShow = true
    },
    // 按交货单入库
    showDelivery () {
      this.dialogDeliveryShow = true
    },
    // 撤销
    revoke () {
      const id = this.id
      request('/api/order/supplier/updatePurchasinglier?' + 'id=' + id, 'post').then((res) => {
        if (res.code === '200') {
          this.$message({
            showClose: true,
            type: 'success',
            message: '操作成功!'
          })
          // this.getIdData()
          this.$router.push({
            path: '/InventoryDelivery/MaterielInLibrary'
          })
        }
      })
    },
    // 撤销弹框
    deliverGoods (data) {
      this.$confirm('确认要撤销吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.revoke()
      })
    },
    // 点击添加物料弹框里的确定按钮
    action () {
      // this.form.bidScoringList = []
      this.btnStarts = 2
      this.cgdStart = 1
      this.ruleOrdersShow = 1
      if (this.clrarList === 2) {
        this.form.bidScoringList = []
      }
      this.clrarList = 1
      var newListData = this.$refs.queryDialog.$refs.content.$refs.libraryData.selectData
      if (newListData.length > 0) {
        // this.form.bidScoringList = newListData
        this.dialogShow = false
        newListData.forEach(item => {
          this.form.bidScoringList.push({ ...item }) // 列表后追加数据
          this.form.bidScoringList.forEach(a => {
            a.units = item.unit
            if (!a.materialCode) {
              this.form.bidScoringList.splice(item, 1)
            }
          })
        })
        newListData.forEach(item => {
          this.form.bidScoringList.forEach(a => {
            a.units = item.unit
          })
        })
      } else {
        this.$message({
          showClose: true,
          message: '请选择需要入库的物料',
          type: 'warning'
        })
      }
    },
    // 点击按订单入库弹框里的确定按钮
    orderBtn () {
      this.form.bidScoringList = []
      this.btnStarts = 2
      this.ruleOrdersShow = 2
      this.cgdStart = 1
      this.clrarList = 2 // 清空列表
      var newListData1 = this.$refs.queryDialog.$refs.content.$refs.libraryData.selectData
      if (newListData1.length > 0) {
        // this.form.bidScoringList = newListData1
        this.dialogOrderShow = false
        newListData1.forEach(item => {
          this.form.bidScoringList.push({ ...item }) // 列表后追加数据
          this.form.bidScoringList.forEach(a => {
            a.materialDesc = item.materialName
            a.materialType = item.materialType
            a.units = item.materiaUnit
            a.ruleOrders = item.orderCode
            a.purchaseNum = item.orderRow
          })
        })
      } else {
        this.$message({
          showClose: true,
          message: '请选择需要入库的物料',
          type: 'warning'
        })
      }
    },
    // 点击按交货单入库弹框里的确定按钮
    deliveryBtn () {
      this.form.bidScoringList = []
      this.btnStarts = 2
      this.cgdStart = 2
      this.clrarList = 2 // 清空列表
      this.ruleOrdersShow = 1
      var newListData2 = this.$refs.queryDialog.$refs.content.$refs.libraryData.selectData
      if (newListData2.length > 0) {
        this.form.bidScoringList = newListData2
        this.dialogDeliveryShow = false
        newListData2.forEach(item => {
          this.form.bidScoringList.forEach(a => {
            a.materialDesc = item.materialName
            a.materialType = item.materialType
            a.units = item.materiaUnit
            a.delivery = item.livraisonCode
            a.deliveryNum = item.livraisonRow
          })
        })
      } else {
        this.$message({
          showClose: true,
          message: '请选择需要入库的物料',
          type: 'warning'
        })
      }
    },
    // 下载普通物料出入库模板
    downCommonTemplate () {
      var tmUrl = '普通出入库模板'
      exportForm('/api/order/PurchasingSystem/downloadMaterialExcel?title=' + tmUrl, 'post').then((res) => {
        try {
          const url = window.URL.createObjectURL(res.data)
          const eleLink = document.createElement('a')
          eleLink.href = url
          eleLink.download = '普通物料入库模板.xls'
          eleLink.target = '_blank'
          eleLink.click()
          window.URL.revokeObjectURL(url)
        } catch (error) {
          console.error('download function error!', error)
        }
      })
    },
    // 下载供应链、外协出入库模板
    downOtherTemplate () {
      var tmUrl = '外协供应链出入库模板'
      exportForm('/api/order/PurchasingSystem/downloadMaterialExcel?title=' + tmUrl, 'post').then((res) => {
        try {
          const url = window.URL.createObjectURL(res.data)
          const eleLink = document.createElement('a')
          eleLink.href = url
          eleLink.download = '外协供应链入库模板.xls'
          eleLink.target = '_blank'
          eleLink.click()
          window.URL.revokeObjectURL(url)
        } catch (error) {
          console.error('download function error!', error)
        }
      })
    },
    // 关闭弹框
    handleClose () {
      this.dialogShow = false
    },
    handleOrderClose () {
      this.dialogOrderShow = false
    },
    handleDeliveryClose () {
      this.dialogDeliveryShow = false
    }
  }
}
</script>

<style scoped lang="scss">
  @import '@/assets/css/elform.scss';
  .xj-code{
    margin-bottom: 20px;
    color: #606266;
  }
  .xj-span1{
    display: inline-block;
    width: 158px;
    text-align: right;
    padding-right: 12px;
    font-size: 14px;
  }
  .xj-span2{
    font-size: 14px;
  }
  .bidding{
    position: relative;
  }
  .bidding-ipn{
    position: absolute;
    left: 205px;
    top: -1px;
    width: 160px;
  }
  .yqgys .content{
    padding: 0 !important;
  }
  .excel-tab{
    margin-top: 10px;
    margin-bottom: 12px;
  }
  // .disabled .excel-tab{
  //   margin-bottom: 0;
  // }
  .btnlaft{
    float: left;
  }
  .mg-btnlaft{
    margin-right: 10px;
  }
  .mg-form{
    // margin-top: 20px;
    .purchClass{
      margin: 10px 0;
    }
    .mg-action{
      margin: 20px 0;
    }
    :deep(.el-table th){
      padding: 4px 0;
      background: #ededed;
      font-size: 14px;
    }
    :deep(.el-table td){
      font-size: 14px;
    }
    :deep(.el-input__inner){
      height: 30px;
      line-height: 30px;
    }
    :deep(.el-input__icon) {
      line-height: 30px !important;
    }
    :deep(.el-input__suffix-inner){
      line-height: 0;
      vertical-align: top;
    }
    // 添加label---css
    :deep(.el-form-item__label:before) {
      content: "*";
      color: #F56C6C;
      margin-right: 4px;
    }
  }
  .perch-mg-form{
    .disabled{
      :deep(.el-textarea__inner){
        background: none;
        border: none;
        font-size: 14px;
        padding: 7px 5px;
        color: #666 !important;
        font-family: 'Microsoft YaHei';
      }
      .el-select__caret{
        display: none;
      }
      .el-input__inner{
        padding: 0;
      }
      thead{
        .el-input__inner{
          font-weight: bold;
        }
      }
      // 移除label---css
      :deep(.el-form-item__label:before) {
        content: "";
        color: #Fff;
        margin-right: 0;
      }
    }
    .el-form .col {
      padding: 0 !important;
    }
  }
</style>
